/**
 * @typedef {Object} CustomizerType
 * @property {'ignored'|'frame'|'design'|'color'|'finish'|'part'|'background'} type
 * @property {string} [target] - Relevant for colors, tells to which color-mask it is to be mapped
 * @property {number} [defaultOrder] - Relevant for parts, specifies the layer order in which images uploaded will be placed (unless overulled by user)
 */


export const customizerTypes = {
  // Ignored WC Questions
  MODEL: {
    type: 'frame',
  },
  SIZE: {
    type: 'ignore',
  },
  // Frame
  DESIGN: {
    type: 'design',
  },
  BACKGROUND: {
    type: 'background',
    defaults: {
      target: 'background',
      zoom: 2,
    },
  },
  // Color/finish
  PAINT_BAC: {
    type: 'color',
    target: 'color-1',
  },
  PAINT_C2: {
    type: 'color',
    target: 'color-2',
  },
  PAINT_C3: {
    type: 'color',
    target: 'color-3',
  },
  PAINT_FINISH: {
    type: 'finish',
  },
  // Parts
  GROUPSET: {
    type: 'part',
    defaultOrder: 8,
  },
  DREAMBUILDER_WHEELS: {
    type: 'part',
    defaultOrder: 2,
  },
  BANDEN_OPTIE_ROAD: {
    type: 'part',
    defaultOrder: 1,
  },
  BANDEN_OPTIE_MTB: {
    type: 'part',
    defaultOrder: 1,
  },
  BANDEN_OPTIE_GRAVEL: {
    type: 'part',
    defaultOrder: 1,
  },
  BANDEN_OPTIE_TIMETRIAL: {
    type: 'part',
    defaultOrder: 1,
  },
  TYPE_OF_KIT: {
    type: 'part',
    defaultOrder: [4, 9],
  },
  CRANKOPTIE: {
    type: 'part',
    defaultOrder: 8,
  },
  ZADEL_OPTIE: {
    type: 'part',
    defaultOrder: 9,
  },
  
  STUURBREEDTE: {
    type: 'part',
    defaultOrder: 9,
  },
  STUURPENLENGTE: {
    type: 'part',
    defaultOrder: 9,
  },
  CASSETTE: {
    type: 'part',
    defaultOrder: 9,
  },
  TYPE_OF_CASSETTE_BODY: {
    type: 'part',
    defaultOrder: 9,
  },
  CRANKLENGTE: {
    type: 'part',
    defaultOrder: 9,
  },
  DISC_BRAKE_ROTOR: {
    type: 'part',
    defaultOrder: 9,
  },
};


/**
 *
 * @param wcQuestion
 * @returns {CustomizerType}
 */
export function customizerTypeFromQuestion(wcQuestion) {
  if (!wcQuestion || (typeof (wcQuestion) !== 'string')) return wcQuestion;
  const normed = wcQuestion.replace(/ /gi, '_').toUpperCase();
  const normedName = normed.substring(normed.indexOf('_') + 1);
  /** @type {CustomizerType} */
  let custType = customizerTypes[normedName];
  if (!custType) {
    if (normedName.startsWith('PAINT_C')) {
      const colorIndex = Number.parseInt(normedName.slice('PAINT_C'.length), 10);
      custType = {
        type: 'color',
        target: `color-${colorIndex}`,
      };
    } else {
      custType = {
        type: 'part',
        defaultOrder: 10,
      };
    }
  }
  // console.log(`${wcQuestion} -> ${normedName} -> ${custType.type}`, custType);
  return custType;
}
