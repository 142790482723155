export const formatDate = (date) => {
  if (date) {
    return new Date(date).toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
  return window.i18nCustomizer.t('notAvailable');
};

export const weeksBetween = (d1, d2) => Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));

export const timeExpired = (lastUpdate) => {
  const previous = new Date(lastUpdate).getTime();
  const current = new Date().getTime();

  return (current - previous) > process.env.VUE_APP_DATA_CACHE_TIME;
};

export default formatDate;
