export function shareUrl(brand, favoriteCode,locale=null){
  // return `https://dev.b2b.cyclingfactory.be/items/buildYourOwn/${modelCode}?code=${favoriteCode}`;
  if(brand.toLowerCase() === 'eddy merckx') {
    return `https://www.eddymerckx.com/${locale?(locale+'/'):''}configurator/configuration/${favoriteCode}`;
  }
  return `https://www.ridley-bikes.com/${locale?(locale+'/'):''}configurator/configuration/${favoriteCode}`;
}


/**
 * Deep-Compare 2 objects. Order of keys in an object may differ, all the rest must be equal.
 * Note: This will run indefenitely if object A contains a circular reference
 * @param objA
 * @param objB
 * @returns {boolean}
 */
export function isEqual(objA, objB) {
  if (typeof objA !== typeof objB) return false;
  if (objA instanceof Array) {
    // Arrays -> If length equal compare all values to be the same
    if (objA.length !== objB.length) return false;
    for (let i = 0; i < objA.length; ++i) {
      if (!isEqual(objA[i], objB[i])) return false;
    }
    return true;
  } else if (typeof objA === "object") {
    // Objects -> Sort entries and compare as arrays
    if ((objA === null) !== (objB === null)) return false;
    const kvpA = Array.from(Object.entries(objA)).sort((a, b) =>
        a[0] < b[0] ? -1 : 1
    );
    const kvpB = Array.from(Object.entries(objB)).sort((a, b) =>
        a[0] < b[0] ? -1 : 1
    );
    return isEqual(kvpA, kvpB);
  } else {
    // Value Primitive
    return objA === objB;
  }
}
