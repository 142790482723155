import axios from 'axios';

export const getAttributeTranslations = async (baseUrl, lang) => {
  const result = await axios.get(
    `${baseUrl}/v1/items/attributetranslations?language=${lang}`,
  );
  return result.data.result;
};

export const getDealersLatLong = async (baseUrl, lat, long, locale) => {
  const result = await axios.get(
    `${baseUrl}/b2c/v1/dealers?lat=${lat}&lng=${long}&locale=${locale}&type=B2CRidleydealerlocator`
  );
  return result.data.result;
};

export const findStock = async (baseUrl, args) => {
  try {
    const res = await axios.get(`${baseUrl}/b2c/V1/stock?${Array.from(Object.entries(args)).map(
      ([k, v]) => `${k}=${encodeURIComponent(v)}`
    ).join('&')
      }`);
    if (res.status === 200) {
      return res.data.result;
    } else {
      throw new Error('FIND_STOCK_FETCH_ERROR');
    }
  } catch (error) {
    console.error('Catching API Error findStock');
    console.log(error);
  }
};


