import axios from 'axios';

export const config = async (baseUrl, ItemId, lang) => {
  const result = await axios.get(`${baseUrl}/v1/customizer/models/${ItemId}?language=${lang}`);
  return result.data;
};

export const configOverwrite = async (url) => {
  const result = await axios.get(url);
  return result.data;
};

export const saveCustomizerFavorite = async (baseUrl, favorite, version, target) => { // saveCustomizerFavorite
  try {
    favorite.version = version;
    favorite.target = target;
    favorite.domain = window.location.hostname;
    const result = await axios.post(`${baseUrl}/v1/customizer/favorite/save`, favorite);
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};


export const loadFavorite = async (baseUrl, favoriteCode, target) => {
  try {
    const domain = window.location.hostname;
    const result = await axios.get(`${baseUrl}/v1/customizer/model/${favoriteCode}?target=${target}&domain=${domain}`);
    return result.data[0];
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
}

export const sendFavorite = async (baseUrl, favoriteCode, email, language, locale, newsletter, dealersNearby, logType, trackingtoken) => {
  try {
    const result = await axios.post(`${baseUrl}/v1/customizer/favorite/send`, { favorite: favoriteCode, email, language, locale, newsletter, dealersNearby, logType, trackingtoken });
    return result.data;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const logFavorite = async (baseUrl, favoriteCode, email, language, locale, newsletter, dealersNearby, logType, userGeo, trackingtoken = null, savedToAccount) => {
  try {
    const result = await axios.post(`${baseUrl}/v1/customizer/favorite/log`, { favorite: favoriteCode, email, language, locale, newsletter, dealersNearby, logType, userGeo, savedToAccount }, { headers: { trackingtoken } });
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

// deze omvormen naar Account
export const updateAccountMyConcept = async (baseUrl, code, email, logtype) => {
  try {
    const res = await axios.post(`${baseUrl}/b2c/v1/concepts/accountUpdate`, { code, email, logtype });

    if (res?.status >= 200 && res?.status < 300) {
      return res.data.result;
    }
  } catch (err) {
    console.log(err);
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};
