function addStyles(win, styles) {
  styles.forEach(style => {
    let link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

export const htmlToPaper = (element, localOptions, cb = () => true) => {
  const defaultOptions = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
    replace: true,
    styles: []
  };

  let {
    name,
    specs,
    replace,
    styles
  } = { ...defaultOptions, ...localOptions };

  specs = specs.length ? specs.join(',') : '';

  if (!element) {
    alert(`Element to print not found!`);
    return;
  }

  const url = '';
  const win = window.open(url, name, specs, replace);

  win.document.write(`
        <html>
          <head>
            <title>${window.document.title}</title>
          </head>
          <body class="m-2">
            ${element.innerHTML}
          </body>
        </html>
      `);

  addStyles(win, styles);

  setTimeout(() => {
    win.document.close();
    win.focus();
    // win.print();
    //win.close();
    cb();
  }, 1000);

  return true;
};
