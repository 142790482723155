import { customizerTypeFromQuestion } from './customizerPreviewTypes';

import Bowser from "bowser";
import semverCoerce from "semver/functions/coerce";
import semverSatisfies from "semver/functions/satisfies";

/**
 */
export function queryARSupport() {
  let parser = Bowser.getParser(window.navigator.userAgent);
  let parsedResult = parser.getResult();
  
  let result = {
    type: "3D",
    format: "gltf", // Use the gltf format by default (e.g. ThreeJS GLTF viewer, Windows 3D viewer, Android ARC Kit, ..)
    handler: null // When no handler is set, we don't know how the device will deal with out 3D file and we will just have browser download the 3D file and let OS do the rest
  }
  
  let osVersion = semverCoerce(parsedResult.os.version);
  if (parser.is('android') && semverSatisfies(osVersion, '>=7')){
    // Android version 7 and up should support ARCore, altough it might need installation by the user
    result.type = 'AR';
    result.handler = 'android'
  } else if (
      (parser.is('iOS') && semverSatisfies(osVersion, '>=11'))
      || (parser.is('macOS') && semverSatisfies(osVersion, '>=10'))
  ){
    // Apple ARkit is supported from iOS 11 and up
    result.type = 'AR';
    result.format = 'usdz';
    result.handler = 'ios';
  }else{
    // Anything else, like windows etc should probably be treated the same: let it download a GLTF file and leave it to the OS
  }
  
  return result;
}

export const arSupport = queryARSupport();


export function getCustomizerArConfig(options, customizer ){
  // Update 2D customizer data
  const context = {
    'finish' : 'glossy',
    'color-1': '#8A0008',
    'color-1-metallic': false,
    'color-2': '#E6E0B8',
    'color-2-metallic': false,
    'color-3': '#181715',
    'color-3-metallic': false,
  };
  const keys = Array.from(Object.keys(options));
  if (!customizer) return;
  
  for (const key of keys) {
    const question = customizer.questions.find((q) => q && q.name === key);
    const option = question?.options?.find((o) => o.$$selected);
    
    if (option) {
      const custType = customizerTypeFromQuestion(option.question);
      const normedName = (option.name || '').toUpperCase();
      const akeneo = option?.$$akeneoLocal || option?.$$akeneo;
      
      if (custType) {
        switch (custType.type) {
          case 'color':
            context[custType.target] = akeneo?.hex_color;
            context[`${custType.target}-metallic`] = akeneo?.metallic_color ? 1 : 0;
            if(akeneo?.prismatic_color) return null; // Abort, prismatic not supported in AR (GLTF/USD)
            break;
          case 'finish':
            if (normedName === 'GLOSSY') {
              context['finish'] = 'glossy';
            } else {
              context['finish'] = 'matte';
            }
            break;
        }
      }
    }
  }
  return context;
}
