/* eslint-disable */

const inlineWorker = func => {
  const functionBody = func
    .toString()
    .trim()
    .match(/^function\s*\w*\s*\([\w\s,]*\)\s*{([\w\W]*?)}$/)[1];

  return new Worker(
    URL.createObjectURL(new Blob([functionBody], { type: "text/javascript" }))
  );
};

export default () => {
  return inlineWorker(function () {
    self.importScripts(
      "https://components.cyclingfactory.be/customizer-utils/1.5/bundle.js"
    );

    self.addEventListener("message", function (event) {
      const { config, id } = event.data;
      const customizer = generateCustomizerConfig(config);
      self.postMessage({ data: customizer, id });
    });
  });
};
