import { render, staticRenderFns } from "./ChoiceListItem.vue?vue&type=template&id=31c5c81e&scoped=true"
import script from "./ChoiceListItem.vue?vue&type=script&lang=js"
export * from "./ChoiceListItem.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "31c5c81e",
  null
  ,true
)

export default component.exports